import type { Store, StoreWritable } from 'effector';
import { combine } from 'effector';

import { persistStore } from '@core/utils/store';

import * as user from '../user/internal';

import {
	AGENT_WEBAPP_CONFIG,
	AGENT_WEBAPP_FEATURES_CONTENT_ID,
	AGENT_WEBAPP_NOTES,
	IMGIX_FALLBACK_CONTENT_ID,
	PROFILING_CONTENT_ID,
} from './constants';
import * as effects from './effects';
import type { AgentConfig, Content, FeatureFlags, ImgIxFallbackFlags, PredefinedNotes, ProfilingFlags } from './model';
import { domain, flagEnabledAndUser, flagEnabledIfOrUser, flagEnabledOrUserOrRole } from './utils';

/**
 * Create content store
 */
export const $content: StoreWritable<Record<string, Content['content']>> = persistStore(domain.store({}), {
	key: 'agent.content',
	sync: true,
}).on(effects.getContentFx.doneData, (_, { data = [] }) =>
	data.reduce((acc, { content, name }) => ({ ...acc, [name]: content }), {}),
);

/**
 * Profiling page flags
 */
export const $profilingFlags: Store<ProfilingFlags> = $content.map(
	({ [PROFILING_CONTENT_ID]: content }) => content || {},
);

/**
 * Feature flags store
 */
export const $featureFlags: Store<FeatureFlags> = $content.map(
	({ [AGENT_WEBAPP_FEATURES_CONTENT_ID]: content }) => content || {},
);

/**
 * Predefined notes store
 */
export const $predefinedNotes: Store<PredefinedNotes> = $content.map(
	({ [AGENT_WEBAPP_NOTES]: content }) => content || {},
);

/**
 * Predefined notes by role
 */
export const $predefinedNotesByRole = combine(
	user.$user,
	$predefinedNotes,
	({ role }, notes) => notes[role]?.sort((a, b) => (a.label > b.label ? 1 : -1)) || [],
);

/**
 * Predefined notes filter by most used
 */
export const $mostUsedNotes = $predefinedNotesByRole.map((notes) =>
	notes.filter(({ chipOrder }) => Boolean(chipOrder)).sort(({ chipOrder: a = 0 }, { chipOrder: b = 0 }) => a - b),
);

/**
 * Bank statement flag
 */
export const $bankDetailsEnabled = combine(user.$info, $featureFlags, (info, { bankDetails }) =>
	flagEnabledAndUser(info.id, bankDetails),
);

/**
 * Last Uploaded flag
 */
export const $documentUploadedAtEnabled = combine(user.$info, $featureFlags, (info, { documentUploadedAt }) =>
	flagEnabledIfOrUser(info.id, documentUploadedAt),
);

/**
 * Bank Details Last Updated flag
 */
export const $bankDetailsLastUpdatedEnabled = combine(user.$info, $featureFlags, (info, { bankDetailsLastUpdated }) =>
	flagEnabledIfOrUser(info.id, bankDetailsLastUpdated),
);

/**
 * Qualify Note flag
 */
export const $qualifyNoteEnabled = combine(user.$info, $featureFlags, (info, { enableQualifyNote }) =>
	flagEnabledAndUser(info.id, enableQualifyNote),
);

/**
 * Agent Upload flag
 */
export const $documentUploadedByAgentEnabled = combine(user.$info, $featureFlags, (info, { documentUploadedByAgent }) =>
	flagEnabledIfOrUser(info.id, documentUploadedByAgent),
);

/**
 * ImgIx fallback flags
 */
export const $imgIxFallback: Store<ImgIxFallbackFlags> = $content.map(
	({ [IMGIX_FALLBACK_CONTENT_ID]: content = {} }) => ({
		...content,
		enabled: content?.dashboard && Math.floor(Math.random() * 101) <= content?.dashboardThrottle,
	}),
);

/**
 * New vehicle page flag
 */
export const $damageAssistantPageEnabled = $featureFlags.map(({ damageAssistant }) => !!damageAssistant?.enabled);

/**
 * Enable OCR Service History table
 */
export const $ocrServiceHistoryEnabled = combine(user.$info, $featureFlags, (info, { ocrServiceHistory }) =>
	flagEnabledAndUser(info.id, ocrServiceHistory),
);

/**
 * OCR Service history labels
 */
export const $ocrStatusLabelEnabled = $featureFlags.map(
	({ ocrServiceHistory }) => !!ocrServiceHistory?.showStatusLabels,
);

/**
 * Control next lead feature
 */
export const $nextLeadEnabled = $featureFlags.map(({ nextLead }) => !!nextLead?.enabled);

/**
 * Control instrument cluster feature
 */
export const $instrumentClusterEnabled = combine(user.$info, $featureFlags, (info, { instrumentCluster }) =>
	flagEnabledOrUserOrRole(info?.id, info?.role?.name, instrumentCluster),
);

/**
 * Enable Matcher Diff Highlighter
 */
export const $matcherDiffHighlighterEnabled = $featureFlags.map(
	({ matcherDiffHighlighter }) => !!matcherDiffHighlighter?.enabled,
);

/**
 * Enable Test Prep Const enquiry
 */
export const $zeroPrepCostEnabled = combine(user.$info, $featureFlags, (info, { zeroPrepCost }) =>
	flagEnabledAndUser(info.id, zeroPrepCost),
);

/**
 * Control SUV/Saloon panel damage feature
 */
export const $panelDamageSuvSaloon = combine(user.$info, $featureFlags, (info, { panelDamageSuvSaloon }) =>
	flagEnabledIfOrUser(info.id, panelDamageSuvSaloon),
);

/**
 * Enable trade vehicles page
 */
export const $tradeVehiclesPage = combine(user.$info, $featureFlags, (info, { tradeVehiclesPage }) => {
	const enabled = flagEnabledAndUser(info.id, tradeVehiclesPage);
	return {
		enabled,
		mockIsTrade: enabled && tradeVehiclesPage?.mockIsTrade,
	};
});

/**
 * Agents config
 */
export const $config: Store<AgentConfig> = $content.map(({ [AGENT_WEBAPP_CONFIG]: content }) => content || {});

/**
 * Enable Christmas theme
 */
export const $christmasTheme = $featureFlags.map(({ christmasTheme }) => !!christmasTheme?.enabled);

/**
 * Enable Mileage History
 */
export const $mileageHistoryEnabled = combine(user.$info, $featureFlags, (info, { mileageRecords }) =>
	flagEnabledIfOrUser(info.id, mileageRecords),
);

/**
 * Enable service history predictions
 */
export const $shPredictionEnabled = combine(user.$info, $featureFlags, (info, { serviceHistoryPrediction }) =>
	flagEnabledIfOrUser(info.id, serviceHistoryPrediction),
);

/**
 * Feature flag original VRM
 */
export const $enableOriginalVrm = $featureFlags.map(({ enableOriginalVrm }) => !!enableOriginalVrm?.enabled);

/**
 * Self serve feature flag
 */
export const $enableSelfServe = combine(user.$info, $featureFlags, (info, { selfServe }) =>
	flagEnabledAndUser(info.id, selfServe),
);

/**
 * KYC details feature flag
 */
export const $dealerKycDetailsEnabled = combine(user.$info, $featureFlags, (info, { dealerKycDetails }) =>
	flagEnabledIfOrUser(info.id, dealerKycDetails),
);

/**
 * new OTP feature flag
 */
export const $dealerPaymentsOTPEnabled = combine(user.$info, $featureFlags, (info, { paymentsOTP }) =>
	flagEnabledIfOrUser(info.id, paymentsOTP),
);
