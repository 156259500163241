/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
	BIDS_PERMISSIONS,
	DEALERS_PERMISSIONS,
	DOCUMENTS_PERMISSIONS,
	INSTANT_MATCH_PERMISSIONS,
	OTP_PERMISSIONS,
	ROLES,
	USERS_PERMISSIONS,
	VEHICLES_PERMISSIONS,
} from '@core/utils/permissions';

import * as app from '../app/internal';

import * as effects from './effects';
import type { UserInfo, UserStats } from './model';
import { domain } from './utils';

/**
 * Create user info store
 */
export const $info = domain
	.store<UserInfo>({} as UserInfo)
	.on(effects.getUserInfoFx.doneData, (_, info) => info)
	.reset(app.reset);

/**
 * Separate user permissions
 */
export const $permissions = $info.map(({ computedPermissions = [] }) => computedPermissions);

/**
 * Create combined user store
 */
export const $user = $info.map((info) => {
	const { computedPermissions = [], role } = info;
	return {
		permissions: computedPermissions,
		info,
		organisation: info.organisation,
		role: role?.name,
		// dealer
		canAddDealer: computedPermissions.includes(DEALERS_PERMISSIONS.DEALERS_ADD),
		canAddDealerContact: computedPermissions.includes(DEALERS_PERMISSIONS.DEALERS_CONTACT_ADD),
		canDeleteDealer: computedPermissions.includes(DEALERS_PERMISSIONS.DEALERS_DELETE),
		canOverrideDealerFee: computedPermissions.includes(DEALERS_PERMISSIONS.DEALERS_FEE_OVERRIDE),
		canManageDealers: computedPermissions.includes(DEALERS_PERMISSIONS.DEALERS_MANAGEMENT),
		canModifyDealerStatus: computedPermissions.includes(DEALERS_PERMISSIONS.DEALERS_MODIFY_STATUS),
		canViewDealers: computedPermissions.includes(DEALERS_PERMISSIONS.DEALERS_VIEW),
		canViewKycDetails: computedPermissions.includes(DEALERS_PERMISSIONS.DEALERS_KYC_READ),
		canEditKycDetails: computedPermissions.includes(DEALERS_PERMISSIONS.DEALERS_KYC_WRITE),
		// bids
		canSeeAllBidsOnSale: computedPermissions.includes(BIDS_PERMISSIONS.ALL_BIDS_DURING_SALE),
		// user
		canAccessDealerContactPermissionsForPayments: computedPermissions.includes(
			USERS_PERMISSIONS.ACCESS_DEALER_CONTACT_PERMISSIONS_FOR_PAYMENTS,
		),
		canEditDelivery: computedPermissions.includes(USERS_PERMISSIONS.DELIVERY_EDIT),
		canEditGrading: computedPermissions.includes(USERS_PERMISSIONS.GRADING_EDIT),
		canViewGrading: ![ROLES.SALES_SPECIALIST, ROLES.OPERATIONS, ROLES.DOCUMENT_REVIEWER].includes(role?.name),
		isAccountManager: computedPermissions.includes(USERS_PERMISSIONS.IS_ACCOUNT_MANAGER),
		isLeadManager: computedPermissions.includes(USERS_PERMISSIONS.IS_LEAD_MANAGER),
		canDeleteNotes: computedPermissions.includes(USERS_PERMISSIONS.NOTES_DELETE),
		isSeniorLeadManager: computedPermissions.includes(USERS_PERMISSIONS.SENIOR_LEAD_MANAGER_VIEW),
		canEditLeadAssignment: computedPermissions.includes(USERS_PERMISSIONS.USERS_EDIT_LEAD_ASSIGNMENT),
		canEditUserPermissions: computedPermissions.includes(USERS_PERMISSIONS.USERS_EDIT_PERMISSIONS),
		canEditProfilePicture: computedPermissions.includes(USERS_PERMISSIONS.USERS_EDIT_PROFILE_PICTURE),
		canManageUsers: computedPermissions.includes(USERS_PERMISSIONS.USERS_MANAGEMENT),
		canSeePrioritisedVehicles: computedPermissions.includes(USERS_PERMISSIONS.VEHICLE_LIST_PRIORITISED),
		canSeeStandardVehicles: computedPermissions.includes(USERS_PERMISSIONS.VEHICLE_LIST_STANDARD),
		canViewUsers: computedPermissions.includes(USERS_PERMISSIONS.USERS_VIEW),
		// vehicle
		canModifyRestrictedVehicleStatus: computedPermissions.includes(
			VEHICLES_PERMISSIONS.MODIFY_RESTRICTED_VEHICLE_STATUS,
		),
		canEditLockedSalesSpecialist: computedPermissions.includes(VEHICLES_PERMISSIONS.EDIT_LOCKED_SALES_SPECIALIST),
		canReassignSoldVehicle: computedPermissions.includes(VEHICLES_PERMISSIONS.VEHICLES_REASSIGN_SOLD),
		canUpdateDocumentationSection: computedPermissions.includes(
			VEHICLES_PERMISSIONS.VEHICLES_UPDATE_DOCUMENTATION_FIELDS,
		),
		canUpdateSpecificationSection: computedPermissions.includes(
			VEHICLES_PERMISSIONS.VEHICLES_UPDATE_SPECIFICATION_FIELDS,
		),
		canOverrideQAValidationCheck: computedPermissions.includes(
			VEHICLES_PERMISSIONS.VEHICLES_OVERRIDE_QA_VALIDATION_CHECK,
		),
		canRefreshPricingAnytime: computedPermissions.includes(VEHICLES_PERMISSIONS.ANYTIME_REFRESH_PRICING),
		// seller documents
		canSeeSellerDocuments: computedPermissions.includes(DOCUMENTS_PERMISSIONS.DOCUMENTS_ACCESS),
		canReviewSellerDocuments: computedPermissions.includes(DOCUMENTS_PERMISSIONS.DOCUMENTS_REVIEW),
		canEditVehicle: computedPermissions.includes(VEHICLES_PERMISSIONS.VEHICLES_UPDATE),
		canViewVehicles: computedPermissions.includes(VEHICLES_PERMISSIONS.VEHICLES_VIEW),
		// vehicle payment
		canSeeVehiclePayment: computedPermissions.includes(VEHICLES_PERMISSIONS.VEHICLE_PAYMENT_VIEW),
		canMarkAsManualPayment: computedPermissions.includes(VEHICLES_PERMISSIONS.MARK_AS_MANUAL_PAYMENT),
		canEditPriceAmendmentForPayments: computedPermissions.includes(
			VEHICLES_PERMISSIONS.EDIT_PRICE_AMENDMENT_FOR_PAYMENTS,
		),
		// instant match
		canEditRetailPrice: computedPermissions.includes(INSTANT_MATCH_PERMISSIONS.SET_RETAIL_PRICE),
		is: {
			accountManager: role?.name === ROLES.ACCOUNT_MANAGER,
			admin: role?.name === ROLES.ADMIN,
			dealerExperienceSpecialist: role?.name === ROLES.DEALER_EXPERIENCE_SPECIALIST,
			dealerSupport: role?.name === ROLES.DEALER_SUPPORT,
			developer: role?.name === ROLES.DEVELOPER,
			documentReviewer: role?.name === ROLES.DOCUMENT_REVIEWER,
			operations: role?.name === ROLES.OPERATIONS,
			paymentOps: role?.name === ROLES.PAYMENT_OPS,
			qa: role?.name === ROLES.QA,
			salesCompletion: role?.name === ROLES.SALES_COMPLETION,
			salesSpecialist: role?.name === ROLES.SALES_SPECIALIST,
			onlineSalesSpecialist: role?.name === ROLES.ONLINE_SALES_SPECIALIST,
			seniorSalesSpecialist: role?.name === ROLES.SENIOR_SALES_SPECIALIST,
			supportSpecialist: role?.name === ROLES.SUPPORT_SPECIALIST,
			transportLogisticsExecutive: role?.name === ROLES.TRANSPORT_LOGISTICS_EXECUTIVE,
		},
		// otp
		canViewAndEditDealerOTPNumber: computedPermissions.includes(OTP_PERMISSIONS.VIEW_EDIT_DEALER_OTP_NUMBER),
	};
});

export const $stats = domain
	.store<UserStats>({
		companyStats: {},
		personalStats: {},
	} as UserStats)
	.on(effects.getUserStatsFx.doneData, (_, stats) => stats)
	.reset(app.reset);
