import { sample } from 'effector';

import * as enquiry from '../vehicle/internal';

import * as effects from './effects';

/**
 * Fetch seller on enquiry id change
 */
sample({
	clock: enquiry.$enquiryId,
	filter: ({ sellerId }) => !!sellerId,
	fn: ({ sellerId }) => sellerId,
	source: enquiry.$enquiry,
	target: effects.getSellerFx,
});
