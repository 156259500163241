import * as app from '../app/internal';

import * as effects from './effects';
import * as events from './events';
import type { InstantMatchRetailPrice } from './model';
import { domain } from './utils';

export const $retailPrice = domain
	.store<InstantMatchRetailPrice>({ discount: null, discountDuration: null, retailPrice: null })
	.on(effects.getRetailPriceFx.doneData, (_, result) => result.data)
	.reset([app.reset, events.reset]);
