import type { Effect } from 'effector';

import type { JSENDAPIResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';

import type { InstantMatchRetailPrice, SetRetailPrice } from './model';
import { domain } from './utils';

export const getRetailPriceFx: Effect<number, JSENDAPIResponse<InstantMatchRetailPrice>> = domain.effect({
	handler: async (enquiryId) =>
		request({
			method: 'get',
			url: `/dealer-retail-price/retail-price/${enquiryId}`,
		} as RequestConfig),
});

export const setRetailPriceFx: Effect<SetRetailPrice, JSENDAPIResponse<InstantMatchRetailPrice>> = domain.effect({
	handler: async ({ discount, discountDuration, enquiryId, retailPrice }) =>
		request({
			data: {
				discount,
				discountDuration,
				retailPrice,
			},
			method: 'post',
			url: `/dealer-retail-price/retail-price/${enquiryId}`,
		} as RequestConfig),
});
