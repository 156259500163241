import { sample } from 'effector';

import * as app from '../app/internal';

import * as effects from './effects';
import * as events from './events';

/**
 * Load initial data
 */
sample({
	clock: events.init,
	target: [effects.getRetailPriceFx],
});

/**
 * Reset feature on app/page reset
 */
sample({
	clock: app.reset,
	target: events.reset,
});

/**
 * Update retail price on set retail price
 */
sample({
	clock: effects.setRetailPriceFx.done,
	fn: ({ params }) => params.enquiryId,
	target: effects.getRetailPriceFx,
});
